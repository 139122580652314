import { type CurrentUser } from "@wpf-ui/gql/generated-gateway/graphql";
import { type NextRouter, useRouter } from "next/router";
import { useEffect, useState } from "react";

export function getAuthPageUrl(): string {
  const query = new URLSearchParams({ redirectUrl: location.href }).toString();
  return `/auth?${query}`;
}

const needsRedirect = (currentUser: CurrentUser | null, isForbidden: boolean, router: NextRouter): boolean => {
  return (
    !currentUser &&
    // 権限が無い場合はNotFoundを表示するのでRedirectはしない
    !isForbidden &&
    !(router.pathname.startsWith("/auth") || router.pathname.startsWith("/set_token"))
  );
};
export function useRequestSignIn(currentUser: CurrentUser | null, isForbidden: boolean): { isRedirecting: boolean } {
  const router = useRouter();
  const [isRedirecting, setIsRedirecting] = useState(needsRedirect(currentUser, isForbidden, router));

  useEffect(() => {
    if (router.pathname === "/projects/[projectId]") {
      setIsRedirecting(false);
      // 募集閲覧ページはログインなしでも参照可能
      return;
    }

    if (needsRedirect(currentUser, isForbidden, router)) {
      void router.push(getAuthPageUrl());
      setIsRedirecting(true);
    }
  }, [router, currentUser, isForbidden]);

  return { isRedirecting };
}
