import { type FC, type ReactNode } from "react";
import { useRouter } from "next/router";
import { useCurrentUser } from "@wpf-ui/contexts";
import { NotFoundPage } from "@wpf-ui/components/fragments/404/NotFoundPage";
import { isAllowedPathForCurrentUser } from "@wpf-ui/utils/auth/authorization";
import { SheSpinner } from "@wpf-ui/components/essentials/spinner/SheSpinner";

export interface GuardProps {
  readonly children: ReactNode;
  readonly isRedirecting: boolean;
}

export const AuthGuard: FC<GuardProps> = ({ children, isRedirecting }) => {
  const router = useRouter();
  const currentUser = useCurrentUser();
  if (isAllowedPathForCurrentUser(router, currentUser)) {
    return <>{children}</>;
  }
  return isRedirecting ? <SheSpinner /> : <NotFoundPage />;
};
