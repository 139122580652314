import { Spinner as ChakraSpinner, Flex } from "@chakra-ui/react";
import { type FC } from "react";

export const SheSpinner: FC = () => {
  return (
    <Flex justifyContent="center" mt="40px">
      <ChakraSpinner w="40px" h="40px" />
    </Flex>
  );
};
